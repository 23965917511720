import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SectorsRepository extends BaseRepository {
    baseUrl = 'management/specifications/sectors';

    getSectors(criteria) {
        return this.httpClient.post(`${this.baseUrl}/get-sectors`, criteria);
    }
}
